import React from 'react';
import { imgBrand } from '../../lib/brand';
import styles from './styles.module.less';

var backgroundImage = imgBrand('Line_large.png', 'Accent');

interface AccentProps {
   align?: String;
}

const Accent = (props: AccentProps) => {
   const { align = 'center' } = props;
   const bg = {
      backgroundImage: `url(${backgroundImage})`,
      margin: align === 'center' ? '12px auto' : '12px 0'
   };

   return (
      <div className={styles.AccentBlock}>
         <hr {...props} className={styles.Accent} style={bg} />
      </div>
   );
};
export default Accent;
