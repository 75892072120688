import React from 'react';

import Accent from '../Accent';

import styles from './styles.module.less';

interface IntroHeaderProps {
   header: string;
   par: string;
   img?: string;
   alt?: string;
   paddingBottom?: string;
}

const IntroHeader = (props: IntroHeaderProps) => {
   const { header, par, img, alt, paddingBottom = '' } = props;
   const customInnerStyle = paddingBottom ? { paddingBottom: '0px' } : {};

   return (
      <div className={styles.IntroHeader}>
         <div className={styles.inner} style={customInnerStyle}>
            {img && <img src={img} alt={alt} />}
            <h1>{header}</h1>
            <Accent />
            <p>{par}</p>
         </div>
      </div>
   );
};

export default IntroHeader;
